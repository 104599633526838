@import "../settings";

// Stop full bleed images becoming too huge.
$global-max-bleed-size: 1000px;

.content-image {
  padding-top: $global-padding * 3;
  padding-bottom: 0;
  position: relative;
  font-family: $header-font-family;
  text-align: center;
  z-index: 2;

  // Add additional margin to pulling image margin down
  margin-bottom: $global-margin * 8;

  @include breakpoint(large) {
    margin-bottom: $global-margin * 10;
  }

  // Multiple content image blocks butted up don't need the top wavy line
  &.no-margin + .content-image {

    &::before {
      display: none;
    }
  }

  &.add-bottom-padding {
    padding-bottom: $global-padding * 6;
  }

  // Of course we're not using standard para size...
  p:not(.eyebrow),
  ul li,
  ol li {
    font-size: rem_calc(18px);

    @include breakpoint(medium) {
      font-size: rem_calc(20px);
    }
  }

  &__grid {
    display: flex;
    grid-gap: $default-grid-gap;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
    }
  }

  &__content {
    order: 1;
    position: relative;

    @include breakpoint(large) {
      align-content: center;
      display: grid;
      flex: 0 0 100%;
      max-width: 100%;
      max-width: calc(50% - #{$global-margin * 3});
      min-height: 545px;
      order: 2;
      padding: 0 ($global-padding * 2);\ flex-basis: calc(50% - #{$global-margin * 3});

      // Stars
      &::before {
        content: "";
        display: block;
        width: 119px;
        height: 112px;
        background-image: url('../images/star-no-bkg.gif');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 10px;
        left: -20px;
      }
    }

    & > *:last-child {
      margin-bottom: 0;

      & *:last-child {
        margin-bottom: 0;
      }
    }

    .button {
      margin-top: $global-margin * 2;
    }
  }

  &__container {
    order: 2;
    position: relative;
    z-index: 2;

    @include breakpoint(large) {
      order: 1;
      flex: 0 0 50%;
    }
  }

  &:not(.overhang-top) {
    .content-image__container {
      margin-bottom: -$global-margin * 4;

      @include breakpoint(large) {
        margin-bottom: -$global-margin * 5;
      }
    }
  }
  &.overhang-top {
    // @include breakpoint( large ) {
    //   margin-top: $global-margin * 9;
    // }

    .content-image__container {
      @include breakpoint(large) {
        margin-top: -$global-margin * 8;
      }
    }
  }

  // Wrapper when figure is used.
  &__figure {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;

    @include breakpoint(medium) {
      flex-basis: calc(50% - #{$global-margin * 3});
      max-width: calc(50% - #{$global-margin * 3});
    }

    @supports (display: grid) {
      max-width: 100%;
    }

    .content-image__image {
      position: initial;
    }
  }

  &__image {
    -webkit-mask-image: url('../images/card-mask.svg');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    aspect-ratio: 0.84 / 1;
    height: auto;
    mask-image: url('../images/card-mask.svg');
    mask-origin: fill-box;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    max-width: 584px;
    object-fit: cover;
    transform: rotate(3deg);
    width: 90%;

    @include breakpoint( large ) {
      width: 100%;
    }
  }

  &--left {
    .content-image__container {
      @include breakpoint( xxlarge ) {
        margin-left: -$global-margin * 3;
      }
    }

    .content-image__image {
      transform: rotate(-3deg);
    }

    .content-image__content {
      &::before {
        background-image: url('../images/star-no-bkg.gif');
        left: auto;
        right: -20px;
      }
    }
  }

  &--right {
    .content-image__container {
      @include breakpoint( xxlarge ) {
        margin-left: $global-margin * 3;
      }
    }

    .content-image__content {
      order: 1;
    }

    .content-image__container,
    .content-image__figure {
      order: 2;
    }

  }
}

// A special rule for when we have an overhanging content image before a relationship block.
.content-image + .relationship {
  padding-top: $global-padding * 6;
}

// A special rule for when we have an overhanging content image with no bottom margin before a block--padding.
.content-image.no-margin + .block--padding {
  padding-top: $global-padding * 6;

  @include breakpoint( large ) {
    padding-top: $global-padding * 10;
  }
}
